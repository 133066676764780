<!-- 8-G -->
 <!-- TODO : 이게 맞음? -->
<template>
    <v-dialog
      :value="dialog"
      @input="dialog = $event"
      max-width="400px"
    >   
      <v-card>
        <v-card-title>채널 세부정보</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-2 pb-0">
          
          <v-list dense >
            <!-- <v-subheader>REPORTS</v-subheader> -->
              <v-list-item>
                
                <v-list-item-icon >
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-icon>
                
                <v-list-item-content>
                  <v-list-item-title> <v-btn rounded small >이메일 주소 보기</v-btn></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                
                <v-list-item-icon >
                  <v-icon>mdi-web</v-icon>
                </v-list-item-icon>
                
                <v-list-item-content>
                  <v-list-item-title>www.grape.com/@rahanart</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            
          </v-list>
        </v-card-text>
        <v-card-actions
        >
          <v-btn
            rounded
            @click="dialog = false"
          >
          <v-icon>mdi-share</v-icon>
            채널 공유
          </v-btn>
          <v-btn
            rounded
            @click="dialog = false"
          >
          <v-icon>mdi-flag-outline</v-icon>
            사용자 신고
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
      selectedItem: 1,
      items: [
        { text: '구독자 46만명', icon: 'mdi-account-voice' },
        { text: '콘텐츠 270개', icon: 'mdi-play-box-outline' },
        { text: '조회수 290,012회', icon: 'mdi-trending-up' },
        { text: '가입일 : 2019.8.1.', icon: 'mdi-information-outline' },
        { text: '대한민국', icon: 'mdi-earth' },
      ],
    }},
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
  </script>